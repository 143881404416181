<template>
  <div class="select-area">
    <div class="niche-area" v-if="list && list.length > 0">
      <div ref="nicheBox" class="niche-box">
        <div class="niche-list">
          <div class="niche-row" v-for="(r, i) in list" :key="i">
            <div
              class="niche-item"
              :class="{
                'niche-select': selectRowKeys.includes(n.id),
              }"
              :style="itemStyle"
              @click="selectNiche(n)"
              v-for="(n, j) in r"
              :key="j"
              :ref="`niche-${n.row}-${n.cols}`"
            >
              <img :src="n.worshipType.img" class="niche-icon" v-if="n.worshipType && n.worshipType.img" />
              <div class="niche-img" v-else></div>
              <div class="niche-num">{{ n.num }}</div>
              <div class="niche-tip" v-if="n.disabled">{{ n.worshipStatus ? '已供' : '禁用'}}</div>
            </div>
          </div>
        </div>
        <div ref="nicheBorder" class="niche-border"></div>
      </div>
    </div>
    <a-empty description="暂无龛位" :image="emptyImage" class="empty-box" v-else-if="list"></a-empty>
    <div class="niche-loading" v-show="loading">
      <a-spin/>
    </div>
    <div class="action-box flex-box vertical" v-if="action && list && list.length > 0">
      <a-popover placement="left">
        <template slot="content">
          1.CTRL键+滚轮缩放 2.长按点击平移
        </template>
        <a-icon type="question-circle" class="question-btn btn" />
      </a-popover>
      <a-icon type="plus-square" class="btn" @click="zoom('big')" />
      <a-icon type="minus-square" class="btn" @click="zoom('small')" />
      <a-icon type="interaction" class="btn" @click="reset" />
    </div>
  </div>
</template>

<script>
import { clone, isArray, paddingZero } from "../../../common/js/tool";
import Panzoom from "@panzoom/panzoom";
import { Empty } from 'ant-design-vue';

export default {
  name: "SelectArea",
  model: {
    event: "change",
  },
  props: {
    value: [Object, Array],
    district_id: Number,
    id: Number,
    action: { type: Boolean, default: true }, // 可操作放大缩小
    multiple: Boolean, // 是否多选
    area: Boolean, // 选择区块
    areaOpen: Boolean, // 区块中的龛位是否可选
    disabled: Boolean, // 是否展示禁用龛位
    loading: Boolean,
    list: Array, // 龛位列表
    blockList: { type: Array, default: () => [] }, // 区块列表
  },
  data() {
    return {
      selectRows: [],
      selectRowKeys: [],
      statusList: [],
      scale: 1,
      panzooming: false,
      panzoom: null,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
  computed: {
    selectArea() {
      return this.multiple && this.area;
    },
    itemStyle() {
      return this.panzooming ? { pointerEvents: "none" } : { pointerEvents: "auto" };
    },
  },
  watch: {
    value() {
      this.setValue();
    },
    list(val) {
      if (val.length > 0) {
        this.$nextTick(() => {
          this.setAreaBlock();
          this.listenEvent();
        });
      }
    },
  },
  created() {
    this.setValue();
  },
  methods: {
    listenEvent() {
      const nicheBox = this.$refs.nicheBox;
      if (!nicheBox) return;
      const panzoom = Panzoom(nicheBox, {
        minScale: 0.2,
        maxScale: 5,
      });
      nicheBox.addEventListener("wheel", (e) => {
        if (!e.ctrlKey) return;
        e.preventDefault();
        panzoom.zoomWithWheel(e);
      });
      nicheBox.addEventListener("panzoomchange", () => {
        this.panzooming = true;
      });
      nicheBox.addEventListener("panzoomend", () => {
        this.panzooming = false;
      });
      this.panzoom = panzoom;
    },
    resetData() {
      this.list = [];
      this.selectRows = [];
      this.selectRowKeys = [];
      this.blockList = [];
    },
    // 设置初始值
    setValue() {
      this.hideBorder();
      if (this.value) {
        let value = clone(this.value);
        if (this.multiple && isArray(value)) {
          let selectRows = value.filter((item) => item.selected);
          if (selectRows.length > 0) {
            selectRows.forEach((item) => (item.selected = false)); // 去除标记
            this.selectRows = selectRows;
            this.selectRowKeys = selectRows.map((item) => item.id);
          } else {
            let rowColumnInfo = this.getMinMaxRowColumn(value);
            this.setSelectRows(rowColumnInfo);
          }
          this.setBorder();
        } else if (!this.multiple && typeof value == "object") {
          this.selectRows = [value];
          this.selectRowKeys = [value.id];
        } else {
          this.selectRows = [];
          this.selectRowKeys = [];
        }
      } else {
        this.selectRows = [];
        this.selectRowKeys = [];
      }
    },
    // 获取龛位属性值
    getNicheProps(num) {
      let props = {
        disabled: false,
        img: null,
      };
      let blockList = this.blockList;
      for (let k = 0; k < blockList.length; k++) {
        let block = blockList[k];
        if (block.id == this.id) {
          // 编辑时，当前区域可选
          continue;
        }
        if (block.num_group.includes(num)) {
          if (!this.areaOpen) {
            props.disabled = true;
          }
          props.img = block.typeInfo?.img;
          break;
        }
      }
      return props;
    },
    // 隐藏区块
    hideBlock() {
      let refs = this.$refs;
      let nicheBoxDom = refs.nicheBox;
      if (!nicheBoxDom) {
        return;
      }
      let blockDoms = nicheBoxDom.querySelectorAll(".area-block");
      blockDoms.forEach((blockDom) => {
        nicheBoxDom.removeChild(blockDom);
      });
    },
    // 设置区块
    setAreaBlock() {
      this.hideBlock();
      if (this.blockList.length == 0) {
        return;
      }
      let refs = this.$refs;
      let nicheBoxDom = refs.nicheBox;
      let frag = document.createDocumentFragment();
      this.blockList.forEach((block) => {
        let { minRow, maxRow, minColumn, maxColumn } = this.getMinMaxRowColumn(block.buddhistNiche);
        if (block.id == this.id) {
          // 当前区域可选
          return;
        }
        let div = document.createElement("div");
        div.textContent = block.name;
        div.classList.add("area-block");
        if (this.areaOpen) {
          div.style.pointerEvents = "none";
          div.style.opacity = 0.5;
        }
        let leftTopDom = refs[`niche-${maxRow}-${minColumn}`][0];
        let rightBottomDom = refs[`niche-${minRow}-${maxColumn}`][0];
        div.style.left = leftTopDom.offsetLeft + "px";
        div.style.top = leftTopDom.offsetTop + "px";
        div.style.width = rightBottomDom.offsetLeft + rightBottomDom.offsetWidth - leftTopDom.offsetLeft + "px";
        div.style.height = rightBottomDom.offsetTop + rightBottomDom.offsetHeight - leftTopDom.offsetTop + "px";
        frag.appendChild(div);
      });
      nicheBoxDom.appendChild(frag);
    },
    setSelectRows({ minRow, maxRow, minColumn, maxColumn }) {
      let list = this.list;
      if (list.length == 0) {
        return;
      }
      let firstNiche = this.getNiche(minRow, minColumn);
      let lastNiche = this.getNiche(maxRow, maxColumn);
      this.selectRowKeys = [firstNiche.id, lastNiche.id];
      this.selectRows = [firstNiche, lastNiche];
    },
    getMinMaxRowColumn(list) {
      let rows = [],
        columns = [];
      list.forEach((item) => {
        let row = item.row;
        let column = item.cols;
        rows.push(row);
        columns.push(column);
      });
      let minRow = Math.min(...rows);
      let maxRow = Math.max(...rows);
      let minColumn = Math.min(...columns);
      let maxColumn = Math.max(...columns);
      return { minRow, maxRow, minColumn, maxColumn };
    },
    getNum(row, column) {
      return paddingZero(row, 2) + paddingZero(column, 3);
    },
    getNiche(row, column) {
      let list = this.list;
      let rowLen = list.length;
      let rowList = list[rowLen - row];
      return rowList[column - 1];
    },
    // 选中区域是否存在无法选中的龛位
    hasDisabledNiche(pre, cur) {
      let flag = false;
      let { minRow, maxRow, minColumn, maxColumn } = this.getMinMaxRowColumn([pre, cur]);
      loop: for (let r = minRow; r <= maxRow; r++) {
        for (let c = minColumn; c <= maxColumn; c++) {
          let block = this.getNiche(r, c);
          let area_id = block.area_id;
          if (this.id) {
            // 编辑
            if (area_id && area_id != this.id) {
              flag = true;
              break loop;
            }
          } else if (area_id) {
            flag = true;
            break loop;
          }
        }
      }
      return flag;
    },
    dispatch(n) {
      let selectRows = this.selectRows;
      let selectRowKeys = this.selectRowKeys;
      if (this.selectArea) {
        // 选择区块
        let len = selectRowKeys.length;
        if (len == 2) {
          let { minRow, maxRow, minColumn, maxColumn } = this.getMinMaxRowColumn(selectRows);
          let rows = [];
          for (let i = minRow; i <= maxRow; i++) {
            for (let j = minColumn; j <= maxColumn; j++) {
              let niche = clone(this.getNiche(i, j));
              if (selectRowKeys.includes(niche.id)) {
                niche.selected = true; // 打上标记
              }
              rows.push(niche);
            }
          }
          this.$emit("change", rows);
        } else {
          this.$emit("change", null);
        }
      } else {
        // 单选
        this.$emit("change", n);
      }
    },
    // 选择龛位
    selectNiche(n) {
      let len = this.selectRowKeys.length;
      if (this.selectArea) {
        // 选择区块
        if (len == 0) {
          this.hideBorder();
          this.selectRows = [n];
          this.selectRowKeys = [n.id];
        } else if (len == 1) {
          this.hideBorder();
          let preNiche = this.selectRows[0];
          let flag = this.hasDisabledNiche(preNiche, n);
          if (flag) {
            // 选中区域存在无法选择的龛位，则重新选择
            this.selectRows = [n];
            this.selectRowKeys = [n.id];
          } else {
            this.selectRows.push(n);
            this.selectRowKeys.push(n.id);
            this.setBorder();
          }
        } else if (len == 2) {
          // 选择第三个不触发其他变化
          this.selectRows.push(n);
          this.selectRowKeys.push(n.id);
          return;
        } else if (len == 3) {
          let preNiche = this.selectRows[2];
          let flag = this.hasDisabledNiche(preNiche, n);
          if (flag) {
            // 选择第四个但区域包含无法选择的龛位，则将第三个替换为当前
            this.selectRows.splice(2, 1, n);
            this.selectRowKeys.splice(2, 1, n.id);
            return;
          } else {
            this.hideBorder();
            this.selectRowKeys = [this.selectRowKeys[2], n.id];
            this.selectRows = [this.selectRows[2], n];
            this.setBorder();
          }
        }
        this.dispatch();
      } else if (!this.multiple) {
        // 单选
        this.selectRows = [n];
        this.selectRowKeys = [n.id];
        this.dispatch(n);
      }
    },
    setBorderWidthAndLeft(borderDom, firstDom, lastDom) {
      borderDom.style.width = lastDom.offsetLeft + lastDom.clientWidth - firstDom.offsetLeft + "px";
      borderDom.style.left = firstDom.offsetLeft + "px";
    },
    setBorderHeigthAndTop(borderDom, firstDom, lastDom) {
      borderDom.style.height = lastDom.offsetTop + lastDom.clientHeight - firstDom.offsetTop + "px";
      borderDom.style.top = firstDom.offsetTop + "px";
    },
    // 隐藏选中边框
    hideBorder() {
      if (!this.selectArea) {
        return;
      }
      let borderDom = this.$refs.nicheBorder;
      if (!borderDom) {
        return;
      }
      borderDom.style.opacity = 0;
    },
    // 设置选中边框
    setBorder() {
      if (!this.selectArea || !this.list.length) {
        return;
      }
      let refs = this.$refs,
        borderDom = refs.nicheBorder,
        selectRows = this.selectRows;
      let firstNiche = selectRows[0];
      let lastNiche = selectRows[1];
      let firstNicheDom = refs[`niche-${firstNiche.row}-${firstNiche.cols}`][0];
      let lastNicheDom = refs[`niche-${lastNiche.row}-${lastNiche.cols}`][0];
      borderDom.style.opacity = 1;
      if (lastNiche.cols >= firstNiche.cols) {
        this.setBorderWidthAndLeft(borderDom, firstNicheDom, lastNicheDom);
      } else {
        this.setBorderWidthAndLeft(borderDom, lastNicheDom, firstNicheDom);
      }
      if (lastNiche.row < firstNiche.row) {
        this.setBorderHeigthAndTop(borderDom, firstNicheDom, lastNicheDom);
      } else {
        this.setBorderHeigthAndTop(borderDom, lastNicheDom, firstNicheDom);
      }
    },
    // 放大缩小
    zoom(type) {
      if (!this.panzoom) return;
      if (type == "big") {
        this.panzoom.zoomIn();
      } else if (type == "small") {
        this.panzoom.zoomOut();
      }
    },
    // 重置
    reset() {
      if (!this.panzoom) return;
      this.panzoom.reset();
    },
  },
};
</script>

<style lang="less">
.area-block {
  position: absolute;
  z-index: 10;
  background-color: #f2ecec;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000;
  font-size: 28px;
  line-height: 32px;
  overflow: hidden;
  cursor: default;
}
</style>
<style lang="less" scoped>
.select-area {
  position: relative;
  display: flex;
}
.niche-area {
  position: relative;
  overflow: auto;
  padding: 6px;
}
.niche-box {
  position: relative;
}
.niche-list {
  width: max-content;
}
.niche-row {
  display: flex;
  &:not(:first-of-type) {
    margin-top: 6px;
  }
}
.niche-item {
  position: relative;
  z-index: 2;
  padding: 4px;
  cursor: pointer;
  &:not(:first-of-type) {
    margin-left: 6px;
  }
  &.niche-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &.niche-select {
    position: relative;
    z-index: 20;
    .niche-img {
      background-image: url(../../../assets/light/niche-select.png);
    }
    .niche-num {
      color: #fff;
      background-color: #ff8c00;
      border-radius: 4px;
      height: 18px;
      line-height: 18px;
      margin-top: 2px;
    }
    .niche-icon {
      opacity: 1;
    }
    .niche-tip {
      color: #fff;
    }
  }
}
.niche-tip {
  position: absolute;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 12px;
}
.niche-num {
  text-align: center;
}
.niche-img {
  width: 40px;
  height: 45px;
  background: url(../../../assets/light/niche.png) center / contain no-repeat;
}
.niche-icon {
  width: 40px;
  height: 45px;
  object-fit: contain;
}
.niche-border {
  opacity: 0;
  position: absolute;
  background-color: #ffc566;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.action-box {
  position: absolute;
  right: 20px;
  top: 80px;
  z-index: 20;
  background-color: #fff;
  box-shadow: 0 0 6px #f2ecec;
  padding: 2px;
  .btn {
    font-size: 32px;
    cursor: pointer;
    &.question-btn {
      font-size: 30px;
      margin-bottom: 6px;
    }
  }
}
.empty-box {
  margin: 48px auto 0;
}
.niche-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
